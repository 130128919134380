import { Button } from "@chakra-ui/react"
import { Link } from "gatsby"
import pick from "lodash-es/pick"
import React, { useState } from "react"
import { useFirebase, useUser } from "../../components/auth/FirebaseContext"
import { DebugLayout } from "../../components/debug/DebugLayout"
import { Json } from "../../components/Json"
import { NoSsr } from "../../components/NoSsrContext"

function Debug() {
  const app = useFirebase()
  const [count, setCount] = useState(0)
  const { user, isLoading } = useUser()

  function logFirebaseApp() {
    debugger
    console.log(app)
  }

  if (!app) return null

  return (
    <>
      {isLoading && <span>Loading user...</span>}
      <br />
      <Button onClick={() => setCount(c => c + 1)}>Count: {count}</Button>
      <Button onClick={logFirebaseApp}>Log Firebase App</Button>
      <Json isAdvanced>
        {{
          count,
          app: pick(app, ["name", "options.projectId"]),
          // name: app?.name,
          // projectId: (app?.options as any)?.projectId,
          userFromReactState: pick(user, ["email", "emailVerified", "uid"]),
          user: pick(app.auth().currentUser, ["email", "emailVerified", "uid"]),
          currentUser: app.auth().currentUser?.toJSON(),
        }}
      </Json>
    </>
  )
}

export default function DebugPage() {
  return (
    <DebugLayout title="Debug">
      <Link to="/debug/other-page">Other Page →</Link>{" "}
      <NoSsr>
        <Debug />
      </NoSsr>
    </DebugLayout>
  )
}
